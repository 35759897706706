<template>
  <div v-if="contacts?.length ?? 0 > 0" class="floating">
    <div class="floating-background">
      <div
        v-for="(item, indexSubMenu) in contacts"
        :key="item.id || indexSubMenu"
        class="floating__item other"
      >
        <NuxtLink :to="item.url" :target="item?.name === 'khuyenmai' ? null : '_blank'" class="floating__item--image">
          <BaseImg v-if="!isBotChromeLighthouse" class="icon icon-bottom" :src="item.img" alt="icon" />
          <div class="tooltip">
            <div class="tooltip-content">
              <span class="description">{{ item?.display_name }}</span>
            </div>
            <div class="arrow-down" />
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
const { isBotChromeLighthouse } = useAppStore()
const { fetchBranchContact } = useContact()
const { data: contacts } = await useAsyncData('contacts', async () => {
  return await fetchBranchContact()
})
</script>
<style lang="scss" scoped src="public/assets/scss/components/desktop/floating-menu/index.scss" />
