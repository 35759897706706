<template>
  <div class="home">
    <BannerCheat v-if="isBotChromeLighthouse" />
    <Banner v-else />
    <LazyHydrationWrapper when-visible>
      <SectionSports />
    </LazyHydrationWrapper>
    <LazyHydrationWrapper when-visible>
      <SectionLobby />
    </LazyHydrationWrapper>
    <LazyHydrationWrapper when-visible>
      <SectionStream />
    </LazyHydrationWrapper>
    <LazyHydrationWrapper when-visible>
      <SectionJackpot />
    </LazyHydrationWrapper>
    <SectionHot />
    <div v-if="isShowScroll" class="home-scroll-top">
      <BaseImg
        src="/assets/images/common/icon-top.svg"
        @click="scrollToTop()"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { LazyHydrationWrapper } from 'vue3-lazy-hydration'
import Banner from '~/components/desktop/pages/home/banner/index.vue'
import SectionSports from '~/components/desktop/pages/home/section-sports/index.vue'
import SectionLobby from '~/components/desktop/pages/home/section-lobby/index.vue'
import SectionJackpot from '~/components/desktop/pages/home/section-jackpot/index.vue'
import SectionHot from '~/components/desktop/pages/home/section-hot/index.vue'
import SectionStream from '~/components/desktop/pages/home/section-stream/index.vue'
import BannerCheat from '~/components/desktop/pages/home/banner/banner-cheat.vue'

import { useAppStore } from '~/store/app'

const isShowScroll = ref<boolean>(false)
const { isBotChromeLighthouse } = useAppStore()
const userScroll = () => {
  isShowScroll.value = window.scrollY > 0
}

onMounted(() => {
  window.addEventListener('scroll', userScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', userScroll)
})

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}
const { $pinia } = useNuxtApp()

</script>

<style scoped lang="scss" src="public/assets/scss/components/desktop/pages/home/index.scss"></style>
