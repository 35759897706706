<template>
  <div class="section-lobby">
    <div class="section-lobby__title">
      <h3>Loại cược</h3>
    </div>
    <div class="section-lobby__content">
      <div v-for="(item, index) in LOBBY_GAMES_MB" :key="index" class="item-game">
        <div v-if="item.jackpot && sumJackpot[item.jackpot] > 0" class="jackpot">
          <AnimateCountUp class="value" :number="sumJackpot[item.jackpot]" :show-animation="isShowAnimation" />
        </div>
        <nuxt-link class="link-game" :to="item.link">
          <BaseImg :relative-src="item.img_mb" :alt="item.title" class="game" lazy />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import BaseImg from '~/components/common/base-img.vue'
import { LOBBY_GAMES_MB } from '~/resources/home/section-lobby'
import { useJackpot } from '~/composables/game/useJackpot'
import { useAppStore } from '~/store/app'
import AnimateCountUp from '~/components/common/animate-count-up.vue'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)
const router = useRouter()
const isShowAnimation = ref(false)

const { fetchSumJackpot } = useJackpot()
onMounted(() => {
  fetchSumJackpot()
})
</script>

<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/section-lobby/index.scss"></style>
