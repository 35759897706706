import { schemaSeoConfig } from '~/constants/seo'

export default defineNuxtPlugin(({ $domainUrl, $config }) => {
  // add schema data seo
  useHead({
    script: schemaSeoConfig($domainUrl, $config.public.STATIC_BASE_URL).map((schema) => ({
      type: 'application/ld+json',
      innerHTML: schema
    }))
  })
})
