<template>
  <div class="home-page">
    <BannerCheat v-if="isBotChromeLighthouse" />
    <SectionBanner v-else />
    <LazyHydrationWrapper when-visible>
      <SectionSports />
    </LazyHydrationWrapper>
    <LazyHydrationWrapper when-visible>
      <SectionLobby />
    </LazyHydrationWrapper>
    <LazyHydrationWrapper when-visible>
      <SectionStream />
    </LazyHydrationWrapper>
    <LazyHydrationWrapper when-visible>
      <SectionJackPot />
    </LazyHydrationWrapper>
    <SectionGameHot />
    <LazyHydrationWrapper when-visible>
      <SectionSupport />
    </LazyHydrationWrapper>
    <SeoContent />
  </div>
</template>
<script setup lang="ts">
import { LazyHydrationWrapper } from 'vue3-lazy-hydration'
import SectionBanner from '~/components/mobile/pages/home/banner/index.vue'
import SectionSports from '~/components/mobile/pages/home/section-sports/index.vue'
import SectionLobby from '~/components/mobile/pages/home/section-lobby/index.vue'
import SectionGameHot from '~/components/mobile/pages/home/section-hot/index.vue'
import SectionJackPot from '~/components/mobile/pages/home/section-jackpot/index.vue'
import SectionSupport from '~/components/mobile/pages/home/section-support/index.vue'
import SectionStream from '~/components/mobile/pages/home/section-stream/index.vue'
import BannerCheat from '~/components/mobile/pages/home/banner/banner-cheat.vue'
import { useAppStore } from '~/store/app'

const SeoContent = defineAsyncComponent(() => import('@/components/common/seo-content.vue'))
const { isBotChromeLighthouse } = useAppStore()

useHead({
  bodyAttrs: {
    class: 'mobile-custom'
  }
})

</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/index.scss"></style>
