<template>
  <div class="fix-height home__top-banner">
    <div class="hero-banner">
      <BaseImg
        relative-src="/assets/images/components/mobile/pages/home/section-banner/resize-banner-cheat.webp"
        alt="hero-banner"
        class="hero-banner-cheat"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/banner/index.scss"></style>
