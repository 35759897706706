import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import useModal from '~/composables/useModal'
import { useLoading } from '~/composables/useLoading'
import { useLobbyGameApi } from '~/api/lobby-game'
import { useAccountApi } from '~/api/account'
import { IGameItem } from '~/types/common'
import { useAccountStore } from '~/store/account'
import { MODAL_TYPES, RESPONSE_STATUS } from '~/config/constant'
import { KEY_EMIT_INIT_MODAL_SPECIAL, MODAL, MODAL_CANCEL_PROMOTION } from '~/constants/modal'
import { ACCOUNT_URLS } from '~/config/page-url'
import { CANCEL_PROMOTION_TOAST } from '~/constants/toast'
import { useInitData } from '~~/composables/user/useInitData'

export default () => {
  const { $pinia, $axios, $alertMsg, $formatNumberWithCommas, $bus } = useNuxtApp()
  const { CANCEL_PROMOTION } = useAccountApi()
  const store = useAppStore($pinia)
  const { setGameItem } = useAppStore()
  const accountStore = useAccountStore($pinia)
  const { plan } = storeToRefs(accountStore)
  const { isLogged, currentUser } = storeToRefs(store)
  const { openModal, closeAllModal } = useModal()
  const { LIVE_CASINO_URL, GAME_URL } = useLobbyGameApi()
  const { isLoading, loading, load } = useLoading()
  const { fetchPlan } = useInitData()
  const route = useRoute()
  const clicked = ref<boolean>(false)
  const navigationCheckLoggedInAndOpenGame = async (item: IGameItem, openNewTab?: any, isDemo: boolean = false) => {
    if (item.maintain) {
      return
    }
    if (!isLogged.value) {
      setGameItem(item)
      navigateTo({
        query: { ...route.query, popup: MODAL_TYPES.LOGIN }
      })
      openModal(MODAL_TYPES.LOGIN)
      return
    }

    // block the game for user's promotion other than promotion refund (promotion refund has package_id = 1)
    const isBlockedGame =
      plan.value.package_id && plan.value.package_id > 1 && item.deny_info && item.deny_info === 'WELCOME'
    if (isBlockedGame) {
      openModal(MODAL_TYPES.BLOCK_GAME)
      return
    }

    if (currentUser?.value?.username === currentUser?.value?.fullname) {
      setGameItem(item)
      openModal(MODAL_TYPES.DISPLAY_NAME)
      return
    }

    setGameItem({})
    if (isLiveCasinoGame(item)) {
      await openLiveCasino(item, openNewTab, isDemo)
    } else {
      await openGame(item, openNewTab, isDemo)
    }
  }

  const openGame = async (item: IGameItem, openNewTab: any, isDemo: boolean) => {
    if (!openNewTab) {
      openNewTab = window.open('about:blank', '_blank')
    }
    loading()
    try {
      const { data: response } = await $axios.get(GAME_URL, {
        params: {
          partnerProvider: item?.partner_provider,
          partnerGameId: item?.partner_game_id,
          ru: route.fullPath,
          is_demo: isDemo
        }
      })
      if (response && response.status === RESPONSE_STATUS.STATUS_OK) {
        setTimeout(() => {
          openNewTab.location.href = response?.data?.url
        }, 200)
      } else {
        $alertMsg('error', response?.message)
      }
      load()
    } catch (error) {
      openNewTab.close()
      load()
      return false
    }
  }

  const openGameByUrl = async (url: string): Promise<void> => {
    const queryString = url.includes('?') ? url.split('?')[1] : url
    const searchParams = new URLSearchParams(queryString)

    const item: Record<string, string> = {}

    searchParams.forEach((value, key) => {
      const camelCaseKey = key.replace(
        /([a-z])([a-z]*)/gi,
        (_, p1, p2) => p1.toLowerCase() + p2.charAt(0).toUpperCase() + p2.slice(1).toLowerCase()
      )
      item[camelCaseKey] = value
    })

    await navigationCheckLoggedInAndOpenGame(item as any)
  }

  const openLiveCasino = async (item: IGameItem, openNewTab: any, isDemo: boolean) => {
    if (!openNewTab) {
      openNewTab = window.open('about:blank', '_blank')
    }
    loading()
    try {
      const { data: response } = await $axios.get(LIVE_CASINO_URL, {
        params: {
          partnerCode: item.partner_provider,
          partnerGameId: item.partner_game_id,
          tableId: item.table_id,
          depositPath: 'account/deposit?type=bank',
          is_demo: isDemo
        }
      })
      if (response && response.status === RESPONSE_STATUS.STATUS_OK) {
        setTimeout(() => {
          openNewTab.location.href = response.data.url
          return false
        }, 200)
      } else {
        $alertMsg('error', response?.message)
      }
      load()
    } catch (error) {
      openNewTab.close()
      load()
      return false
    }
  }

  const isLiveCasinoGame = (item: IGameItem) => {
    return item.type === 'casino'
  }

  const openSport = async (api: string) => {
    if (!isLogged.value) {
      navigateTo({
        query: { ...route.query, popup: MODAL_TYPES.LOGIN }
      })
      openModal(MODAL_TYPES.LOGIN)
      return
    }
    loading()
    try {
      const { data: response } = await $axios.get(api)
      if (response && response.status === RESPONSE_STATUS.STATUS_OK) {
        setTimeout(() => {
          window.open(response.data.url, '_blank')
        }, 200)
      } else {
        $alertMsg('error', response?.message)
      }
      load()
    } catch (error) {
      load()
      return false
    }
  }

  const resetClicked = () => {
    setTimeout(() => {
      clicked.value = false
    }, 500)
  }

  const cancelPromotion = async () => {
    try {
      const res = await $axios.$post(CANCEL_PROMOTION)
      if (res && res.status === 'OK') {
        $alertMsg('success', CANCEL_PROMOTION_TOAST.SUCCESS)
        setTimeout(() => {
          fetchPlan()
          navigateTo(`${ACCOUNT_URLS.DEPOSIT_DA}`)
        }, 200)
      } else {
        $alertMsg('error', res.message)
      }
    } catch (error) {}
  }

  const cancelPromotionConfirm = () => {
    if (clicked.value) {
      return false
    }
    clicked.value = true

    MODAL_CANCEL_PROMOTION.message =
      `<p>Số dư trong ví của bạn là: <span>${$formatNumberWithCommas(currentUser?.value.balance)} G</span></p>` +
      `<p>Yêu cầu tiền cược: <span>${$formatNumberWithCommas((plan?.value?.rolling ?? 0) / 1000)} G</span><p/>` +
      '<p>Nhấn nút <span>XÁC NHẬN HỦY</span> - Khuyến mãi sẽ bị hủy, số dư, yêu cầu tiền cược sẽ được thiết lập về 0<p/>'
    $bus.$emit(KEY_EMIT_INIT_MODAL_SPECIAL, {
      ...MODAL_CANCEL_PROMOTION,
      onActionLink: () => {
        closeAllModal()
      },
      onActionButton: () => {
        closeAllModal()
        cancelPromotion()
      }
    })

    resetClicked()
  }

  return {
    isLoading,
    openGame,
    navigationCheckLoggedInAndOpenGame,
    openGameByUrl,
    openSport
  }
}
