<template>
  <div class="section-support">
    <div class="section-item">
      <h3 class="section-item__title">LIÊN HỆ</h3>
      <div class="section-item__content">
        <nuxt-link v-for="(item, index) in contacts" :key="index" :to="item.url" class="item">
          <BaseImg :relative-src="item.img" alt="icon" />
          <span class="item__title">{{ item.display_name }}</span>
        </nuxt-link>
      </div>
    </div>
    <div class="section-item">
      <h3 class="section-item__title">Thông tin chung</h3>
      <div class="section-item__content">
        <nuxt-link v-for="(item, index) in info" :key="index" :to="item.link" class="item">
          <BaseImg :relative-src="item.img" alt="icon" />
          <span class="item__title">{{ item.title }}</span>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PAGE_URLS } from '~/config/page-url'
import { PAGE_PARAMS } from '~/constants/common'
const { fetchBranchContact, contacts } = useContact()

useAsyncData(() => fetchBranchContact())

const info = [
  {
    img: '/assets/images/components/mobile/pages/home/section-support/news.svg',
    title: 'Tin Tức',
    link: PAGE_URLS.NEWS
  },
  {
    img: '/assets/images/components/mobile/pages/home/section-support/introduction.svg',
    title: 'Giới Thiệu',
    link: `${PAGE_URLS.INTRODUCTION}/nha-cai`
  },
  {
    img: '/assets/images/components/mobile/pages/home/section-support/policy.svg',
    title: 'Điều Khoản',
    link: `${PAGE_URLS.INTRODUCTION}/${PAGE_PARAMS.POLICY}`
  },
  {
    img: '/assets/images/components/mobile/pages/home/section-support/security.svg',
    title: 'Bảo Mật',
    link: `${PAGE_URLS.INTRODUCTION}/${PAGE_PARAMS.SECURITY}`
  }
]
</script>

<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/section-support/index.scss"></style>
