<template>
  <div class="container-custom stream_container">
    <div class="title-wrapper">
      <div class="title-wrapper__title">
        <h3>Live casino</h3>
      </div>
      <div class="live-stream-tab">
        <div v-for="stream in homeLiveStreams" :key="stream.id" class="stream">
          <Live :data-live="stream" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { homeLiveStreams } from '~/constants/home/stream'
import { useJackpot } from '~/composables/game/useJackpot'
import Live from '~/components/desktop/pages/home/section-stream/live.vue'
const { fetchJackpot } = useJackpot()

onMounted(() => {
  fetchJackpot()
})

</script>

<style scoped lang="scss" src="public/assets/scss/components/desktop/pages/home/section-stream/index.scss"></style>
