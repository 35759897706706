import { defineStore, acceptHMRUpdate } from 'pinia'
import COOKIES_DEVICE from './cookies-device'
import { ICategory } from '~/types/news'
import { ISeo, IContact } from '~/types/seo'
import { ICategoryPromotion } from '~/types/promotion'
import { seoApi } from '~/api/seo'

interface AppState {
  currentUser: {
    tp_token?: string
    [key: string]: any
  } | null
}

export const useAppStore = defineStore('app', {
  state: (): {
    jackpot: any
    sumJackpot: any
    currentUser: any
    newsCategories: ICategory[]
    promotionCategories: ICategoryPromotion[],
    seoData: ISeo[],
    contact: IContact[]
    transactionDetail: any
    refreshInterval: any
    currentUserRank: number
    openDepositFrom: string
    openWithdrawFrom: string
    gameItem: Object
    plan: any
    listBanks: any
    isBotChromeLighthouse: boolean
    logoHeader: string | null
    logoFooter: string | null
  } => ({
    currentUser: null,
    jackpot: {},
    sumJackpot: {},
    newsCategories: [],
    promotionCategories: [],
    seoData: [],
    contact: [],
    transactionDetail: null,
    refreshInterval: null,
    currentUserRank: 0,
    gameItem: {},
    openDepositFrom: '',
    openWithdrawFrom: '',
    plan: null,
    listBanks: [],
    isBotChromeLighthouse: false,
    logoHeader: null,
    logoFooter: null
  }),
  getters: {
    isLogged: (state): boolean => !!state.currentUser,
    getToken: (state): string | undefined => state.currentUser?.tp_token
  },
  actions: {
    setJackpot(jackpot: any) {
      this.jackpot = jackpot
    },
    setSumJackpot(sumJackpot: any) {
      this.sumJackpot = sumJackpot
    },
    setRefreshInterval(payload: null) {
      this.refreshInterval = payload
    },
    mutate({ property, with: newValue }: { property: keyof AppState; with: any }) {
      this.$state[property] =
        typeof newValue === 'object' && newValue !== null ? { ...this.$state[property], ...newValue } : newValue
    },
    setCurrentUser(user: any) {
      this.currentUser = user ? { ...user } : null
    },
    setCurrentUserRank(payload: any) {
      this.currentUserRank = payload
    },
    setGameItem(payload: Object) {
      this.gameItem = payload
    },
    setDepositFrom(payload: string) {
      this.openDepositFrom = payload
    },
    setOpenWithdrawFrom(payload: string) {
      this.openWithdrawFrom = payload
    },
    setLogoHeader(payload: string) {
      this.logoHeader = payload
    },
    setLogoFooter(payload: string) {
      this.logoFooter = payload
    },
    updateCurrentUser(updates: any) {
      if (updates) {
        this.currentUser = { ...this.currentUser, ...updates }
      } else {
        this.currentUser = null
      }
    },
    updateTransactionDetail(payload: any) {
      this.transactionDetail = payload
    },
    setNewsCategories(cat: ICategory[]) {
      this.newsCategories = cat ?? []
    },
    setPromotionCategories(cat: ICategoryPromotion[]) {
      this.promotionCategories = cat ?? []
    },
    setSeoData(data: ISeo[]) {
      this.seoData = data ?? []
    },
    setContactData(data: IContact[]) {
      this.contact = data ?? []
    },
    updatePlan(payload: any) {
      this.plan = payload
    },
    setListBanks(payload: any) {
      this.listBanks = payload
    },
    async fetchSeoData({ $axios }: Record<string, any>) {
      const { SEO } = seoApi()
      const resultAPI = await $axios.get(SEO)
      if (resultAPI.data?.status === 'OK') {
        const result = resultAPI.data.data
        const dataSeo: ISeo[] = []
        result.forEach((ISeo: ISeo) => {
          const { children, ...parentWithoutChildren } = ISeo
          dataSeo.push(parentWithoutChildren)
          if (ISeo.children) {
            ISeo.children.forEach((child: ISeo) => {
              dataSeo.push(child)
            })
          }
        })
        this.setSeoData(dataSeo)
      }
    },
    ...COOKIES_DEVICE
  },
  persist: true
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
