import { NCC_TYPES } from '~/constants/common'

export interface ILobbySport {
  background: string
  img?: string
  title?: string
  name?: string
  class?: string
  link: string
  detail?: any
  type?: string,
  isCheckLogin?: boolean
}
export const LOBBY_SPORTS: ILobbySport[] = [
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-ksport.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/ksport-item.webp',
    name: 'ksports',
    link: '/the-thao/ksports'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-asport.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/asport-item.webp',
    name: 'asports',
    link: '/the-thao/asports'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-psport.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/psport-item.webp',
    name: 'psport',
    link: '/the-thao/psports',
    class: 'psport'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-esports.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/esports-item.webp',
    name: 'the-thao-ao',
    link: '/the-thao/the-thao-ao',
    class: 'esport',
    isCheckLogin: true
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-ga28.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/bg-ga28.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/bg-ga28.webp',
    name: 'da-ga-28',
    link: '/the-thao/ksports',
    detail: {
      name: 'Đá Gà Livestream',
      type: 'game',
      game_type: 'COCKFIGHT',
      partner_game_id: 'ga28-cockfight',
      partner_provider: 'ga28',
      report_game_id: 'ga28-cockfight',
      deny_info: '',
      is_demo: false,
      table_id: ''
    }
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-ga168.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/bg-ga168.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/bg-ga168.webp',
    name: 'da-ga-168',
    link: '/the-thao/the-thao-dien-tu',
    class: 'esports',
    detail: {
      name: 'Đá Gà Livestream',
      type: 'game',
      game_type: 'COCKFIGHT',
      partner_game_id: 'ws168-cockfight',
      partner_provider: 'ws168',
      report_game_id: 'ws168-cockfight',
      deny_info: '',
      is_demo: false,
      table_id: ''
    }
  }
]

export const LOBBY_SPORTS_MB: ILobbySport[] = [
  {
    background: '/assets/images/components/mobile/pages/home/section-sport/k-sports.webp',
    name: 'ksports',
    link: NCC_TYPES.KSPORTS
  },
  {
    background: '/assets/images/components/mobile/pages/home/section-sport/a-sports.webp',
    name: 'asports',
    link: NCC_TYPES.ASPORTS
  },
  {
    background: '/assets/images/components/mobile/pages/home/section-sport/p-sports.webp',
    name: 'psports',
    link: NCC_TYPES.PSPORTS
  },
  {
    type: 'da-ga',
    background: '/assets/images/components/mobile/pages/home/section-sport/ga-28.webp',
    name: 'da-ga',
    link: '',
    detail: {
      name: 'Đá Gà Livestream',
      type: 'game',
      game_type: 'COCKFIGHT',
      partner_game_id: 'ga28-cockfight',
      partner_provider: 'ga28',
      report_game_id: 'ga28-cockfight',
      deny_info: '',
      is_demo: false,
      table_id: ''
    }
  }
]
