import Swal from 'sweetalert2/dist/sweetalert2.js'
import { APP_ENV } from '~/constants/env'
import 'sweetalert2/dist/sweetalert2.css'

export default defineNuxtPlugin(({ $config, ssrContext }) => {
  const route = useRoute()

  const getRoute = (clientRoute: any) =>
    clientRoute ||
    route || {
      name: '',
      params: {
        slug: ''
      },
      fullPath: ''
    }

  const Toast = Swal.mixin({
    toast: true,
    showConfirmButton: false,
    timerProgressBar: false,
    showCloseButton: false,
    position: 'bottom'
  })

  const fullSrc = (src: string) => {
    return !/^(https?:)?\/\//.test(src) ? `${$config.public.STATIC_BASE_URL}${src}` : src
  }

  const iconType = (icon: string) => {
    const icons = {
      success: '<span class="icon-toast icon-toast-success"></span>',
      error: '<span class="icon-toast icon-toast-error"></span>',
      warning: '<span class="icon-toast icon-toast-warning"></span>'
    }
    return icons[icon] || icons.error
  }

  const getContent = (content: string) => {
    return `<a class='content'>${content}</a>`
  }

  const alertMsg = (icon: string, title: string, timer = 4000) =>
    Toast.fire({
      title: iconType(icon),
      html: getContent(title),
      customClass: {
        container: icon
      },
      timer,
      footer: '<span class="icon-toast icon-toast-loading"></span>'
    })

  // Get the hostname (client-side: window.location.origin, server-side: headers.host)
  const protocol = [APP_ENV.STG, APP_ENV.PROD].includes(process.env.APP_ENV || '') ? 'https' : 'http'
  const domainName = process.client ? window.location.host : ssrContext?.event?.node?.req?.headers?.host
  const domainUrl = process.client ? window.location.origin : `${protocol}://${domainName}`

  return {
    provide: {
      getRoute,
      alertMsg,
      domainUrl
    }
  }
})
